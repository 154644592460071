import { Injectable } from '@angular/core';

import { ToastService } from './toast.service';
import { AuthService } from 'src/app/auth/service/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {
  constructor(
    private toast: ToastService,
    private authService: AuthService
  ) {}

  handleError(error: any) {
    let errorMessage = 'An unknown error occurred!';
    try {
      if (error.error.message) {
        errorMessage = error.error.message;
      } else if (error.error.error.message) {
        errorMessage = error.error.error.message;
      }
      errorMessage = this.errorMapper(errorMessage);
    } catch (error) {
      errorMessage = 'An unknown error occurred!';
    }
    this.toast.showError(errorMessage);
    return error;
  }

  errorMapper(message: string): string {
    if (message.includes('expired')) {
      this.authService.logout();
      return 'Session expired, please login again!';
    }
    return message;
  }
}
