import { Component } from "@angular/core";

import { PAGE_NOT_FOUND_CONSTANTS } from "../shared/constants/page-not-found-contansts";
import { environment } from "src/environments/environment";
import { LoaderService } from "../shared/services/loader.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent {
  constructor(private loaderService : LoaderService) {}
  constants = PAGE_NOT_FOUND_CONSTANTS;

  redirectToLogin(): void {
    this.loaderService.showLoader();
    window.location.href = environment.ssoUrl;
  }
}
