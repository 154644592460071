<div class="container">
  <img
    src="../../assets/images/page-under-construction/page_under_construction.svg"
    alt="page under construction"
    class="page-under-construction-img"
    draggable="false"
  />
  <h1 class="page-under-construction-heading">
    This page is currently under construction!
  </h1>
</div>
