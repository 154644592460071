<div class="page-not-found">
  <img
    class="error-image"
    src="\assets\images\page-not-found\page_not_found.svg"
    alt="Page Not Found"
  />
  <h2 class="error-message">{{ constants.ERROR_MESSAGE }}</h2>
  <p class="error-description">{{ constants.ERROR_DESCRIPTION }}</p>
  <button class="common-btn" (click)="redirectToLogin()">
    {{ constants.LOGIN_PAGE }}
  </button>
</div>
