export const NAVBAR_CONSTANTS = {
  HOME: 'Home',
  OVERVIEW: 'Overview',
  VISITORS: 'Visitors',
  APPROVALS: 'Approvals',
  CARDS: 'Cards',
  lOGOUT: 'Logout',
  LOGGED_OUT_SUCCESSFULLY: 'Logged out successfully',
  VMS: 'Visitors'
};
