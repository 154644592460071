<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <img
        src="assets\images\loginpage\watchguard_logo.webp"
        alt="watchguard logo"
        draggable="false"
      />
    </a>
    <button
      *ngIf="isLoggedIn"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarNavAltMarkup"
      *ngIf="isLoggedIn"
    >
      <div class="navbar-nav me-auto">
        <a
          class="nav-link"
          routerLink="vms/dashboard/{{ user.role }}"
          routerLinkActive="active"
          data-bs-target="#navbarNavAltMarkup"
          >{{ navbarConstants.VMS }}</a
        >
      </div>
      <div class="d-flex username-logout-container ms-auto">
        <div class="username d-flex align-items-center me-3">
          <i class="fas fa-user me-2"></i> {{ user.name | uppercase }}
        </div>
        <a class="common-btn" (click)="logout()" tabindex="0"
          ><i class="fas fa-sign-out-alt"></i> {{ navbarConstants.lOGOUT }}</a
        >
      </div>
    </div>
  </div>
</nav>
