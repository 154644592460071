import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const loginService = inject(AuthService);
  const loadedUser = loginService.loadUserFromSession();
  if (loadedUser?.jwt_token) {
    if (state.url === '/home') {
      return true;
    }
    return checkRouteAccess(loadedUser.role, state.url);
  }
  window.location.href = environment.ssoUrl;
  return false;
};

function checkRouteAccess(userRole: string, routeUrl: string): boolean {
  if (routeUrl.includes(userRole)) {
    return true;
  }
  return false;
}
